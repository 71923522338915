import React from "react"
import { DefaultLayout } from "layouts"
import { Contact } from "containers"

import bgHeader from "../images/bg-header.png"
import { Forms } from "components"
import mapImage from "../images/map.svg"

const contactFormProps: IContactForm = {
  venues: [
    {
      name: "B&F Auckland",
      phone: "(272) 211-7370",
      address: "455 West Orchard Street",
      email: "support@yourbrand.com",
    },
    {
      name: "B&F ChristChurch",
      phone: "(272) 211-7370",
      address: "455 West Orchard Street",
      email: "support@yourbrand.com",
    },
    {
      name: "B&F Wellington",
      phone: "(272) 211-7370",
      address: "455 West Orchard Street",
      email: "support@yourbrand.com",
    },
    {
      name: "B&F WestCoast",
      phone: "(272) 211-7370",
      address: "455 West Orchard Street",
      email: "support@yourbrand.com",
    },
  ],
  mapImage,
  headline: "Contact Form",
  description:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  label: {
    branch: "Select Branch",
    department: "Select Department",
    name: "Your Name",
    email: "Your Email",
    subject: "Subject (optional)",
    message: "Message",
  },
  options: {
    branch: [
      {
        label: "B&F Auckland",
        value: "B&F Auckland",
      },
      {
        label: "B&F ChristChurch",
        value: "B&F ChristChurch",
      },
      {
        label: "B&F Wellington",
        value: "B&F Wellington",
      },
      {
        label: "B&F WestCoast",
        value: "B&F WestCoast",
      },
    ],
    department: [
      {
        label: "B&F Auckland",
        value: "B&F Auckland",
      },
      {
        label: "B&F ChristChurch",
        value: "B&F ChristChurch",
      },
    ],
  },
  policyText: "Accept Terms & Conditions",
  submitText: "Send Message",
}

const boxWrapperProps = {
  heroProps: {
    background: bgHeader,
    headline: "Contact Us",
  },
  boxContent: <Forms.Contact {...contactFormProps} />,
  breadcrumbProps: {
    paths: [
      {
        path: "/",
        label: "Home",
      },
      {
        path: "/contact",
        label: "Contact Us",
      },
    ],
  },
}

const buildingAddressProps: IBuildingAddress = {
  headline: "Others ways to contact us",
  description:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna",
  venues: [
    {
      name: "B&F Auckland",
      address: "1772 Nevskaya Street NW, Suite 21389, Atlanta, GA 902344",
    },
    {
      name: "B&F ChristChurch",
      address: "1772 Nevskaya Street NW, Suite 21389, Atlanta, GA 902344",
    },
    {
      name: "B&F Wellington",
      address: "1772 Nevskaya Street NW, Suite 21389, Atlanta, GA 902344",
    },
    {
      name: "B&F WestCoast",
      address: "1772 Nevskaya Street NW, Suite 21389, Atlanta, GA 902344",
    },
  ],
}

const contactPageProps: IContactContainerProps = {
  boxWrapperProps,
  buildingAddressProps,
}

const Index = (props: any) => {
  return (
    <DefaultLayout {...props}>
      <Contact {...contactPageProps} />
    </DefaultLayout>
  )
}

export default Index
